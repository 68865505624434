export const environment = {
    production: false,
    brandCode: 'marufuku',
    env: 'dev',
    firebase: {
        apiKey: 'AIzaSyBy3tBRW1JjKmMe3DK-_iRAVRSYRk872OA',
        authDomain: 'mc-app-marufuku-dev.firebaseapp.com',
        projectId: 'mc-app-marufuku-dev',
        storageBucket: 'mc-app-marufuku-dev.appspot.com',
        messagingSenderId: '257896261204',
        appId: '1:257896261204:web:513d45bf684627fad5afc1',
        measurementId: 'G-XCR0X46N62',
    },
    hostingBaseURL: 'https://app-dev.chukasoba-marufuku.jp',
    appScheme: 'marufuku-monogatari-app-dev',
    storeURL: {
        ios: 'https://apps.apple.com/app/id6470181807',
        android: 'https://play.google.com/store/apps/details?id=jp.chukasoba_marufuku.members',
    },
}
